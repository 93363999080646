var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('el-pagination',{staticStyle:{"padding":".5rem"},attrs:{"background":"","layout":"total,prev, pager,next","total":_vm.total,"page-size":15,"current-page":_vm.page},on:{"current-change":_vm.pageChange}})]},proxy:true}])},[_c('el-table-column',{attrs:{"prop":"active.id","label":"编号","width":"80"}}),_c('el-table-column',{attrs:{"prop":"active.title","label":"活动名称","width":"200"}}),_c('el-table-column',{attrs:{"prop":"active.mode","formatter":(item)=>item.active ? _vm.modeText(item.active.mode) : '',"label":"活动类型","width":"100"}}),_c('el-table-column',{attrs:{"prop":"active.fee","label":"金额","width":"80"}}),_c('el-table-column',{attrs:{"label":"创建时间"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_vm._v(_vm._s(_vm._f("date")(s.row.active.created_at)))]}}])}),_c('el-table-column',{attrs:{"show-overflow-tooltip":"","label":"代理商","prop":"active.agent.title","width":"120"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","align":"center","width":"320"},scopedSlots:_vm._u([{key:"default",fn:function(s){return [_c('el-button',{staticStyle:{"margin-right":"1rem"},attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.push({
          name:'activeEdit',
          params:_vm.$route.params,
          query:{
            id:s.row.active.id
          }
          })}}},[_vm._v("编辑活动 ")]),_c('el-popconfirm',{staticStyle:{"margin-right":"1rem"},attrs:{"title":"确认要审核通过？"},on:{"confirm":function($event){return _vm.acThis(s.row)}}},[_c('el-button',{attrs:{"slot":"reference","type":"success","size":"small"},slot:"reference"},[_vm._v("审核通过")])],1),_c('el-popconfirm',{attrs:{"title":"确认要删除？"},on:{"confirm":function($event){return _vm.delThis(s.row.active.id)}}},[_c('el-button',{attrs:{"slot":"reference","type":"danger","size":"small"},slot:"reference"},[_vm._v("删除活动")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }